import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function Nav() {
  const navigate = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };



    return (
        <>
                     <div className="sidenav" id="sidenav-main">
            {/* Sidenav header */}
            <div className="sidenav-header d-flex align-items-center">
              <a className="navbar-brand" href="/dashboard">
                <img src="/assets/images/logo.png" className="navbar-brand-img" alt="logo" />
              </a>
              <div className="ml-auto">
                {/* Sidenav toggler */}
                <div className="sidenav-toggler sidenav-toggler-dark d-md-none" data-action="sidenav-unpin" data-target="#sidenav-main">
                  <div className="sidenav-toggler-inner">
                    <i className="bg-white sidenav-toggler-line" />
                    <i className="bg-white sidenav-toggler-line" />
                    <i className="bg-white sidenav-toggler-line" />
                  </div>
                </div>
              </div>
            </div>
            {/* User mini profile */}
            <div className="text-center sidenav-user d-flex flex-column align-items-center justify-content-between">
              {/* Avatar */}
              <div>
                <a href="#" className="avatar rounded-circle avatar-xl">
                  <i className="fas fa-user-circle fa-4x" />
                </a>
                <div className="mt-4">
                  <h5 className="mb-0 text-white"> {userdetails.fullname}</h5>
                  <span className="mb-3 text-sm text-white d-block opacity-8">online</span>
                  <a href="#" className="shadow btn btn-sm btn-white btn-icon rounded-pill hover-translate-y-n3">
                    <span className="btn-inner--icon"><i className="far fa-coins" /></span>
                    <span className="btn-inner--text">${userdetails.balance}</span>
                  </a>
                </div>
              </div>
              {/* User info */}
              {/* Actions */}
              <div className="mt-4 w-100 actions d-flex justify-content-between">
              </div>
            </div>
            {/* Application nav */}
            <div className="clearfix nav-application">
              <a href="/Dashboard" className="text-sm btn btn-square active">
                <span className="btn-inner--icon d-block"><i className="far fa-home fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Home</span>
              </a>
              <a href="/deposit" className="text-sm btn btn-square   ">
                <span className="btn-inner--icon d-block"><i className="far fa-download fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Deposit</span>
              </a>
              <a href="/Withdrawal" className="text-sm btn btn-square  ">
                <span className="btn-inner--icon d-block"><i className="fas fa-arrow-alt-circle-up fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Withdraw</span>
              </a>
              <a href="/ProfitsHistory" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fal fa-history fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Profit History</span>
              </a>
              <a href="/Transactions" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fas fa-money-check-alt fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Transactions</span>
              </a>
              {/* <a href="/asset-balance" className="text-sm btn btn-square  ">
                <span className="btn-inner--icon d-block"><i className="fab fa-stack-exchange fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Swap Crypto</span>
              </a> */}
              <a href="/TransferFunds" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fas fa-exchange fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Transfer funds</span>
              </a>
              <a href="/ManagedAccount" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="far fa-receipt fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Managed Accounts</span>
              </a>
              <a href="/Profile" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fas fa-address-card fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Profile</span>
              </a>
              <a href="/TradingPlans" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fas fa-hand-holding-seedling fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Trading Plans</span>
              </a>
              <a href="/Myplans" className="text-sm btn btn-square  ">
                <span className="btn-inner--icon d-block"><i className="far fa-hand-holding-seedling fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">My Plans</span>
              </a>
              <a href="/Refferals" className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="fas fa-retweet fa-2x" /></span>
                <span className="pt-2 btn-inner--icon d-block">Referrals</span>
              </a>
              <a onClick={logout} className="text-sm btn btn-square ">
                <span className="btn-inner--icon d-block"><i className="far fa-sign-out-alt" /></span>
                <span className="pt-2 btn-inner--icon d-block">logout</span>
              </a>
            </div>
            {/* Misc area */}
            <div className="card bg-gradient-warning">
              <div className="card-body">
                <h5 className="text-white">Need Help!</h5>
                <p className="mb-4 text-white">
                  Contact our 24/7 customer support center
                </p>
                <a href="/support" className="btn btn-sm btn-block btn-white rounded-pill">Contact Us</a>
              </div>
            </div>
          </div>
        </>
    )
}

export default Nav