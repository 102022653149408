import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyBi8zTCDXQFHFLa3OuQJeqHQVYiACzgo9w",
  authDomain: "capitalgoldtrades.firebaseapp.com",
  databaseURL: "https://capitalgoldtrades-default-rtdb.firebaseio.com",
  projectId: "capitalgoldtrades",
  storageBucket: "capitalgoldtrades.appspot.com",
  messagingSenderId: "225190028307",
  appId: "1:225190028307:web:ec89b2b4e3b5c2ce088be6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()