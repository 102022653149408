import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Profile() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  
const [password, setpassword] = useState("")
const [password2, setpassword2] = useState("")
const [fullname, setfullname] = useState("")
const [address, setaddress] = useState("")
const [phonenumber, setphonenumber] = useState("")
const [dateOfBirth, setdateOfBirth] = useState("")
const [country, setcountry] = useState("")


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
           setfullname(doc.data().fullname)
           setaddress(doc.data().Address)
           setphonenumber(doc.data().Phoneno)
           setdateOfBirth(doc.data().DateOfBirth)
          // Phoneno:phonenumber,
          // Address:address,
          // fullname:fullname,
          // DateOfBirth:dateOfBirth,
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };


  
  const updateUserProfile = async () => {

    let userid = f.auth().currentUser;
    let userids = userid.uid;
    var docRef = db.collection("users").doc(userids);
    console.log(phonenumber,address,fullname,dateOfBirth)
    console.log(fullname)
    const update = await docRef
        .update({
          Phoneno:phonenumber,
          Address:address,
          fullname:fullname,
          DateOfBirth:dateOfBirth,
            // address: address
        })
        .then(function () {
            console.log("Document successfully updated!");
            Swal.fire(
                'Update Complete!',
                'Your account has been updataed.',
                'success'
              )
        })
        .catch(function (error) {
            console.error("Error updating document: ", error);
        });
};


const changepassword = () => {

    if (password == "") {
        Swal.fire(
            'No Selected Passowrd?',
            'Please Select A Valid Password',
        )
        return

    }

    if (password != password2) {
        Swal.fire(
            'Password does not match?',
            'Password must match',
        )
        return
    }
    setloading(true)
    var user = f.auth().currentUser;
    user
        .updatePassword(password)
        .then(function () {
            setloading(false)
            Swal.fire({
                icon: 'success',
                title: 'password changed',
                showConfirmButton: false,
                timer: 1500
            })
        })
        .catch(function (error) {
            console.log(error)
            setloading(false)
            Swal.fire(
                'Something went wrong',
                `${error.message}`,
            )

        });
};

const save=()=>{
  Swal.fire({
    icon: 'success',
    title: 'Account has been updated successfully',
    showConfirmButton: false,
    timer: 1500
})
}

  return (
    <div>

      <div className="container-fluid container-application">
        {/* Sidenav */}
        <Nav />
        {/* Content */}
        <div className="main-content position-relative">
          {/* Main nav */}
          {/* Main nav */}
          <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
            <div className="container-fluid">
              {/* Brand + Toggler (for mobile devices) */}
              <div className="pl-4 d-block d-md-none">
                <a className="navbar-brand" href="/dashboard">
                  <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                </a>
              </div>
              {/* User's navbar */}
              <div className="ml-auto navbar-user d-lg-none">
                <ul className="flex-row navbar-nav align-items-center">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user-circle fa-2x" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Navbar nav */}
              <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                {/* Right menu */}
                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="media media-pill align-items-center">
                        <span className="avatar rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                        <div className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Page content */}
          <div className="page-content">
            {/* Page title */}
            <div className="page-title">
              <div className="row justify-content-between align-items-center">
                <div className="mb-3 col-md-6 mb-md-0">
                  <h5 className="mb-0 text-white h3 font-weight-400">Account Settings</h5>
                </div>
              </div>
            </div>
            <div>
            </div>    <div>
            </div>    <div>
            </div>    <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row profile">
                      <div className="p-2 col-md-12 p-md-4">
                        <ul className="mb-4 nav nav-pills bg-light p-2">
                          <li className="nav-item">
                            <a href="#per" className="nav-link active" data-toggle="tab">Personal Settings</a>
                          </li>
                          <li className="nav-item">
                            <a href="#set" className="nav-link" data-toggle="tab">Withdrawal Settings</a>
                          </li>
                          <li className="nav-item">
                            <a href="#pas" className="nav-link" data-toggle="tab">Password/Security</a>
                          </li>
                          <li className="nav-item">
                            <a href="#sec" className="nav-link" data-toggle="tab">Other Settings</a>
                          </li>
                        </ul>
                        <div className="tab-content p-2 p-md-4">
                          <div className="tab-pane fade show active" id="per">
                            <form id="updateprofileform">
                              <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />    <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label className>Fullname</label>
                                  <input type="text" className="form-control " defaultValue={fullname} onChange={(e)=>{setfullname(e.target.value)}} name="name" />
                                </div>
                                <div className="form-group col-md-6">
                                  <label className>Email Address</label>
                                  <input type="email" className="form-control " defaultValue={userdetails.email} name="email" readOnly />
                                </div>
                                <div className="form-group col-md-6">
                                  <label className>Phone Number</label>
                                  <input type="text" className="form-control " defaultValue={phonenumber} onChange={(e)=>{setphonenumber(e.target.value)}} name="phone" />
                                </div>
                                <div className="form-group col-md-6">
                                  <label className>Date of Birth</label>
                                  <input type="date" defaultValue={dateOfBirth} className="form-control " onChange={(e)=>{setdateOfBirth(e.target.value)}} name="dob" />
                                </div>
                                {/* <div className="form-group col-md-6">
                                  <label className>Country</label>
                                  <input type="text" defaultValue className="form-control " name="country" readOnly />
                                </div> */}
                                <div className="form-group col-md-6">
                                  <label className>Address</label>
                                  <textarea className="form-control " placeholder="Full Address" onChange={(e)=>{setaddress(e.target.value)}} name="address" row={3} defaultValue={address} />
                                </div>
                              </div>
                              <button type="button" className="btn btn-primary" onClick={updateUserProfile}>Update Profile</button>
                            </form>
                          </div>






                          <div className="tab-pane fade" id="set">
                            <form id="updatewithdrawalinfo">
                              <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />    <input type="hidden" name="_method" defaultValue="PUT" />    <fieldset>
                              </fieldset>
                              <fieldset className="mt-2">
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label className>Bitcoin</label>
                                    <input type="text" name="btc_address" defaultValue="bc1q5lf6jprreepycv9r8g7nukjsu5gkayjwdldsak" className="form-control " placeholder="Enter Bitcoin Address" />
                                    <small className>Enter your Bitcoin Address that will be used to withdraw your funds</small>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className>Ethereum</label>
                                    <input type="text" name="eth_address" defaultValue className="form-control " placeholder="Enter Etherium Address" />
                                    <small className>Enter your Ethereum Address that will be used to withdraw your funds</small>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className>Litecoin</label>
                                    <input type="text" name="ltc_address" defaultValue className="form-control " placeholder="Enter Litcoin Address" />
                                    <small className>Enter your Litecoin Address that will be used to withdraw your funds</small>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label>USDT.TRC20</label>
                                    <input type="text" name="usdt_address" defaultValue="bc1q5lf6jprreepycv9r8g7nukjsu5gkayjwdldsak" className="form-control" placeholder="Enter USDT.TRC20 Address" />
                                    <small className>Enter your USDT.TRC20 wallet Address that will be used to withdraw your
                                      funds</small>
                                  </div>
                                </div>
                              </fieldset>
                              <button type="button" className="px-5 btn btn-primary" onClick={save}>Save</button>
                            </form>
                          </div>






                          <div className="tab-pane fade" id="pas">
                            <form>
                              <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />    <input type="hidden" name="_method" defaultValue="PUT" />    <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label className>Old Password</label>
                                  <input type="password" name="current_password"  className="form-control " required />
                                </div>
                                <div className="form-group col-md-6">
                                  <label className>New Password</label>
                                  <input type="password" name="password" className="form-control " onChange={(e)=>{setpassword(e.target.value)}} required />
                                </div>
                                <div className="form-group col-md-6">
                                  <label className>Confirm New Password</label>
                                  <input type="password" name="password_confirmation" className=" form-control" onChange={(e)=>{setpassword2(e.target.value)}} required />
                                </div>
                              </div>
                              <button type="button" className="btn btn-primary"  onClick={changepassword}>Update Password</button>
                            </form>
                            <div className="mt-4">
                              <a  className="text-decoration-none">Advance Account Settings <i className="fas fa-arrow-right" /> </a>
                            </div>                              </div>



                          <div className="tab-pane fade" id="sec">
                            <form >
                              <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />    <input type="hidden" name="_method" defaultValue="PUT" />    <div className="row">
                                <div className="mb-3 col-md-6">
                                  <label className>Send confirmation OTP to my email when withdrawing my funds.</label>
                                  <div className="selectgroup">
                                    <label className="selectgroup-item">
                                      <input type="radio" name="otpsend" id="otpsendYes" defaultValue="Yes" className="selectgroup-input" defaultChecked />
                                      <span className="selectgroup-button">Yes</span>
                                    </label>
                                    <label className="selectgroup-item">
                                      <input type="radio" name="otpsend" id="otpsendNo" defaultValue="No" className="selectgroup-input" />
                                      <span className="selectgroup-button">No</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className>Send me email when i get profit.</label>
                                  <div className="selectgroup">
                                    <label className="selectgroup-item">
                                      <input type="radio" name="roiemail" id="roiemailYes" defaultValue="Yes" className="selectgroup-input" defaultChecked />
                                      <span className="selectgroup-button">Yes</span>
                                    </label>
                                    <label className="selectgroup-item">
                                      <input type="radio" name="roiemail" id="roiemailNo" defaultValue="No" className="selectgroup-input" />
                                      <span className="selectgroup-button">No</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className>Send me email when my investment plan expires.</label>
                                  <div className="selectgroup">
                                    <label className="selectgroup-item">
                                      <input type="radio" name="invplanemail" id="invplanemailYes" defaultValue="Yes" className="selectgroup-input" defaultChecked />
                                      <span className="selectgroup-button">Yes</span>
                                    </label>
                                    <label className="selectgroup-item">
                                      <input type="radio" name="invplanemail" id="invplanemailNo" defaultValue="No" className="selectgroup-input" />
                                      <span className="selectgroup-button">No</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="mt-2 col-12">
                                  <button type="button" className="px-5 btn btn-primary" onClick={save}>Save</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
            <div className="text-center row text-sm-left align-items-sm-center">
              <div className="col-sm-6">
                <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                  2023</p>
              </div>
              <div className="text-right col-sm-6 text-md-center">
                <div id="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Scripts */}
      {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
      {/* Bootstrap Notify */}
      {/* Page JS */}
      {/* Purpose JS */}
      {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
    </div>
  )
}

export default Profile