import React from 'react'
import Nav from '../Components/Nav'
function WithdrawalComplete() {
    return (
        <div>

            <div className="container-fluid container-application">
                <Nav />
                {/* Content */}
                <div className="main-content position-relative">
                    {/* Main nav */}
                    {/* Main nav */}
                    <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
                        <div className="container-fluid">
                            {/* Brand + Toggler (for mobile devices) */}
                            <div className="pl-4 d-block d-md-none">
                                <a className="navbar-brand" href="https://getonlinetrader.pro/otprocl/dashboard">
                                    <img src="https://getonlinetrader.pro/otprocl/storage/app/public/photos/Jo4SZ9GGvtk7CDprfhKVphNeTxsA5G7J0hx5mRFZ.png" className="navbar-brand-img" alt="..." />
                                </a>
                            </div>
                            {/* User's navbar */}
                            <div className="ml-auto navbar-user d-lg-none">
                                <ul className="flex-row navbar-nav align-items-center">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="avatar avatar-sm rounded-circle">
                                                <i className="fas fa-user-circle fa-2x" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, Big Jwett!</h6>
                                            <a href="https://getonlinetrader.pro/otprocl/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* Navbar nav */}
                            <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                                {/* Right menu */}
                                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="media media-pill align-items-center">
                                                <span className="avatar rounded-circle">
                                                    <i className="fas fa-user-circle fa-2x" />
                                                </span>
                                                <div className="ml-2 d-none d-lg-block">
                                                    <span className="mb-0 text-sm font-weight-bold">Big Jwett</span>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, Big Jwett!</h6>
                                            <a href="https://getonlinetrader.pro/otprocl/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* Page content */}
                    <div className="page-content">
                        {/* Page title */}
                        <div className="page-title">
                            <div className="row justify-content-between align-items-center">
                                <div className="mb-3 col-md-6 mb-md-0">
                                    <h5 className="mb-0 text-white h3 font-weight-400">Withdrawal Details</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>	<div>
                        </div>    <div className="row">





                            {/* Next Step Components */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-5 row">
                                            <div className="col-lg-8 offset-md-2">
                                                <div className="p-2 rounded p-md-4 card ">
                                                    <div className="card-body">
                                                        <div className="mb-3 alert alert-modern alert-success">
                                                            <span className="text-center badge badge-success badge-pill">
                                                                Your payment method
                                                            </span>
                                                            <span className="alert-content">Tether</span>
                                                        </div>
                                                        <form action="https://getonlinetrader.pro/otprocl/dashboard/completewithdrawal" method="post">
                                                            <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />                                        <div className="form-group">
                                                                <label className>Enter Amount to withdraw($)</label>
                                                                <input className="form-control " placeholder="Enter Amount" type="number" name="amount" required />
                                                            </div>
                                                            <input defaultValue="Tether" type="hidden" name="method" />
                                                            <div className="form-group">
                                                                <h5 className>Enter Tether Address </h5>
                                                                <input className="form-control " placeholder="Enter Tether Address" type="text" name="details" required />
                                                                <small className>Tether is not a default withdrawal option in your account, please enter the correct wallet address to recieve your funds.</small>
                                                            </div>
                                                            <div className="form-group">
                                                                <button className="btn btn-primary" type="submit">Complete Request</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>








                        </div>
                    </div>
                    {/* Footer */}
                    <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
                        <div className="text-center row text-sm-left align-items-sm-center">
                            <div className="col-sm-6">
                                <p className="mb-0 text-sm">All Rights Reserved © OnlineTraderpro
                                    2023</p>
                            </div>
                            <div className="text-right col-sm-6 text-md-center">
                                <div id="google_translate_element" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Scripts */}
            {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
            {/* Bootstrap Notify */}
            {/* Page JS */}
            {/* Purpose JS */}
        </div>
    )
}

export default WithdrawalComplete