import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function ManagedAccount() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          setdataready(true)
        } else {
          console.log("No such document!");
          setloading(false)

        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const [Duration, setDuration] = useState("")
  const [login, setlogin] = useState("")
  const [AccountPass, setAccountPass] = useState("")
  const [Accountname, setAccountname] = useState("")
  const [AccountType, setAccountType] = useState("")
  const [Currency, setCurrency] = useState("")
  const [Leverage, setLeverage] = useState("")
  const [Server, setServer] = useState("")
  const UpdateTradingAccounts = async () => {
    if (AccountPass == "" || Accountname == "" || AccountType == "" || Currency == ""|| Leverage == "" || Server == "") {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Fill all the required fields to proceed",
            footer: '<a href="">Why do I have this issue?</a>'
        })
        return 0
    }
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      TradingAccounts: firebase.firestore.FieldValue.arrayUnion({
        Duration: Duration,
        login: login,
        AccountPass: AccountPass,
        Accountname: AccountPass,
        Accountname: Accountname,
        AccountType: AccountType,
        Currency: Currency,
        Leverage: Leverage,
        Server: Server,
        date: Date.now(),
      }),
    });
    Swal.fire({
      icon: 'success',
      title: 'Trading Account Has Been Added',
      showConfirmButton: false,
      timer: 1500
    })

  };

  return (
    <div>

      <div className="container-fluid container-application">
        {/* Sidenav */}
        <Nav />
        {/* Content */}
        <div className="main-content position-relative">
          {/* Main nav */}
          {/* Main nav */}
          <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
            <div className="container-fluid">
              {/* Brand + Toggler (for mobile devices) */}
              <div className="pl-4 d-block d-md-none">
                <a className="navbar-brand" href="/dashboard">
                  <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                </a>
              </div>
              {/* User's navbar */}
              <div className="ml-auto navbar-user d-lg-none">
                <ul className="flex-row navbar-nav align-items-center">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user-circle fa-2x" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Navbar nav */}
              <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                {/* Right menu */}
                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="media media-pill align-items-center">
                        <span className="avatar rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                        <div className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Page content */}
          <div className="page-content">
            {/* Page title */}
            <div className="page-title">
              <div className="row justify-content-between align-items-center">
                <div className="mb-3 col-md-6 mb-md-0">
                  <h5 className="mb-0 text-white h3 font-weight-400">Trading Account(s)</h5>
                </div>
              </div>
            </div>
            <div>
            </div>    <div>
            </div>    <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-5 row">
                      <div className="shadow-lg col-lg-12 card p-lg-3 p-sm-5">
                        <h2 className>Capital Gold Finex Account manager</h2> <br />
                        <div clas="well">
                          <p className="text-justify ">Don’t have time to trade or learn how to
                            trade?
                            Our Account Management Service is The Best Profitable Trading Option for you,
                            We can help you to manage your account in the financial MARKET with a simple
                            subscription model.<br />
                            <small>Terms and Conditions apply</small><br />Reach us at rowwoe691@via.tokyo.jp
                            for more info.
                          </p>
                        </div>
                        <br />
                        <div className="py-3">
                          <a className="text-white btn btn-primary" data-toggle="modal" data-target="#submitmt4modal">
                            Subscribe Now
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="p-2 mb-5 p-md-4 row">
                      <div className="mb-3 col-12">
                        <h5 className>My Trading Accounts</h5>
                      </div>

                      {
                        dataready && (
                          userdetails.TradingAccounts.map((obj, i) => (

                            <div className="col-md-4 p-2">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">{userdetails.email}/{obj.Accountname}
                                  </h5>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Currency</span>
                                    <span>{obj.Currency}</span>
                                  </div>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Leverage</span>
                                    <span>{obj.Leverage}</span>
                                  </div>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Server</span>
                                    <span>{obj.Server}</span>
                                  </div>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Duration</span>
                                    <span>{obj.Duration}</span>
                                  </div>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Account Password</span>
                                    <span>xxxxxxx</span>
                                  </div>
                                  <div className="d-flex justify-content-between mb-2">
                                    <span className="text-primary">Status</span>
                                    <span>Active</span>
                                  </div>
                                  <div className="d-flex justify-content-center mb-2">
                                    <small>Submitted:
                                    {new Date(obj.date).toDateString()}</small>
                                  </div>
                                  {/* <div className="d-flex justify-content-center mb-2">
                                    <small>
                                      Started:
                                      Not Started yet
                                    </small>
                                  </div> */}
                                  {/* <div className="d-flex justify-content-center mb-2">
                                    <small>Expire:
                                      Not Started yet
                                    </small>
                                  </div> */}
                                  {/* <div className="mt-4 text-center">
                                    <a href="#" data-toggle="modal" className="btn btn-danger btn-sm" onclick="deletemt4()">Cancel</a>
                                    <a  className="btn btn-success btn-sm">Renew</a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      }
                

                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h3 className>Connect to your trading account to monitor activities on
                          your trading account(s).</h3>
                        <iframe src="https://trade.mql5.com/trade" name="WebTrader" title="Subscription Trade" frameBorder={0} style={{ display: 'block', border: 'none', height: '76vh', width: '80vw' }} />
                      </div>
                    </div>
                    {/* end of chart */}
                  </div>
                </div>
              </div>
            </div>












            {/* Submit MT4 MODAL modal */}
            <div id="submitmt4modal" className="modal fade" role="dialog">
              <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header ">
                    <h4 className="modal-title text-">Subscribe to subscription Trading</h4>
                    <button type="button" className="close text-" data-dismiss="modal">×</button>
                  </div>
                  <div className="modal-body ">
                    <form role="form" method="post" action="/dashboard/savemt4details">
                      <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />                    <div className="form-row">
                        <div className="form-group col-md-6">
                          <label className="text-">Subscription Duration</label>
                          <select className="form-control  text-" onChange={(e)=>{setDuration(e.target.value)}} name="duration" id="duratn">
                            <option value="default">Select duration</option>
                            <option>Monthly</option>
                            <option>Quaterly</option>
                            <option>Yearly</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text-">Amount to Pay</label>
                          <input className="form-control subamount  text-"  type="text" id="amount" disabled /><br />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Login*:</label>
                          <input className="form-control  text-" type="text" onChange={(e)=>{setlogin(e.target.value)}} name="userid" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Account Password*:</label>
                          <input className="form-control  text-" type="text"onChange={(e)=>{setAccountPass(e.target.value)}} name="pswrd" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Account Name*:</label>
                          <input className="form-control  text-" type="text" onChange={(e)=>{setAccountname(e.target.value)}} name="name" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Account Type:</label>
                          <input className="form-control  text-" placeholder="E.g. Standard" onChange={(e)=>{setAccountType(e.target.value)}} type="text" name="acntype" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Currency*:</label>
                          <input className="form-control  text-" placeholder="E.g. USD" onChange={(e)=>{setCurrency(e.target.value)}} type="text" name="currency" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Leverage*:</label>
                          <input className="form-control  text-" placeholder="E.g. 1:500" type="text" onChange={(e)=>{setLeverage(e.target.value)}} name="leverage" required />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="text- ">Server*:</label>
                          <input className="form-control  text-" placeholder="E.g. HantecGlobal-live" onChange={(e)=>{setServer(e.target.value)}} type="text" name="server" required />
                        </div>
                        <div className="form-group col-12">
                          <small className="text-">Amount will be deducted from your Account
                            balance</small>
                        </div>
                        <div className="form-group col-md-6">
                          <input id="amountpay" type="hidden" name="amount" />
                          <input type="button" onClick={UpdateTradingAccounts} className="btn btn-primary" defaultValue="Subscribe Now" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /plans Modal */}




          </div>
          {/* Footer */}
          <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
            <div className="text-center row text-sm-left align-items-sm-center">
              <div className="col-sm-6">
                <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                  2023</p>
              </div>
              <div className="text-right col-sm-6 text-md-center">
                <div id="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Scripts */}
      {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
      {/* Bootstrap Notify */}
      {/* Page JS */}
      {/* Purpose JS */}
      {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
    </div>
  )
}

export default ManagedAccount