import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function TradingPlans() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };


  const [amount, setamount] = useState(0)


  const investments = [
    {
      id: "1",
      name: "BASIC BUNDLE",
      minimum: "50",
      Maximum: "1000",
      profits: "20",
      duration: "1"
    },
    {
      id: "2",
      name: "BUSINESS BUNDLE",
      minimum: "2000",
      Maximum: "4000",
      profits: "30",
      duration: "2"
    },
    {
      id: "3",
      name: "PREMIUM BUNDLE",
      minimum: "6000",
      Maximum: "10000",
      profits: "50",
      duration: "3"
    },
    {
      id: "4",
      name: "MINING PLAN",
      minimum: "20000",
      Maximum: "1000000000000",
      profits: "200",
      duration: "7"
    },
    {
      id: "5",
      name: "OIL AND GAS CONTRACT ",
      minimum: "30000",
      Maximum: "1000000",
      duration: "30",
      profits: "65"
    },
    {
      id: "6",
      name: "REAL ESTATE CONTRACT",
      minimum: "20000",
      Maximum: "70000",
      duration: "30",
      profits: "53"
    }
  ]

  const planactivation = (value) => {
    let SelectedInvestment = investments.find(o => o.id === value);
    // console.log(obj)
    if (amount !== "") {
      if (userdetails.currentSubscription == null) {
        Swal.fire({
          title: 'Are you sure?',
          text: `Do you relly want to invest in ${SelectedInvestment.name}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, continue'
        }).then((result) => {
          if (result.isConfirmed) {
            if (
              parseInt(userdetails.balance) !== 0 &&
              parseInt(amount) <= parseInt(userdetails.balance)
            ) {

              if (parseInt(amount) >= SelectedInvestment.minimum && parseInt(amount) <= SelectedInvestment.Maximum) {
                let amount1 = parseInt(amount);
                // if (value == "1" && amount1 >= 300 && amount1 <= 10000) {
                let bal = parseInt(userdetails.balance);
                const newbal = bal - amount1;
                // 92 no of days 1.8 is percent 
                let due = (parseFloat(SelectedInvestment.duration) * ((parseFloat(SelectedInvestment.profits) / 100) * amount1)) + amount1;
                let myDate = new Date();
                // multiply number of days with 24
                const d2 = addHoursToDate(myDate, (parseFloat(SelectedInvestment.duration) * 24)).getTime()
                var cap = new Date(d2);
                console.log(cap.toString());
                const subscription = {
                  amount: amount1,
                  currentPlan: SelectedInvestment.name,
                  dueAmount: due,
                  dueDate: d2,
                  dateSubscribed: new Date().getTime(),
                };
                updateUserBalanceAndSub(newbal, subscription, SelectedInvestment.name, due);
                // reffralCheck()
                // handleClick2("activation of BASIC was successful");
                // }
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'You selected the wrong investment for selected amount',
                  footer: '<a href="">Why do I have this issue?</a>'
                })
              }

            } else {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'you dont have enough balance proceed to funding section to credit your account!',
                footer: '<a href="">Why do I have this issue?</a>'
              })
              //   handleClick();
              //show toast
            }
          }
        })


      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You Have Plan Running Currently!',
        })

      }
      //check this
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'fill in the requied fields!',
        footer: '<a href="">Why do I have this issue?</a>'
      })
      //show toast
    }
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const CreatUserObj = async (obj, subsription) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      fullname: userdetails.fullname,
      email: userdetails.email,
      userid: userids,
      info: obj,
      subsription: subsription

    };
    const userdetail1 = await db
      .collection("investments")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
        Swal.fire({
          icon: 'success',
          title: 'Investment has been activated',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const updateUserBalanceAndSub = (bal, subsription, plans, intrest) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(intrest);
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: subsription,
        totalearnings: increment,
        Investments: firebase.firestore.FieldValue.arrayUnion({
          date: Date.now(),
          plan: plans,
          amount: amount,
        }),
      })
      .then(function () {
        console.log("Document successfully updated!");
        CreatUserObj(
          {
            date: Date.now(),
            plan: plans,
            amount: amount,
          },
          subsription

        )
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };




  // const reffralCheck = () => {
  //   if (userdetails.referreduserid == "nnnnn") {
  //       console.log("np reffreal")
  //   } else {
  //       //increament referreduserid balance with 5% of invested amount
  //       var washingtonRef = db.collection("users").doc(userdetails.referreduserid);
  //       const increment = firebase.firestore.FieldValue.increment((5 * parseInt(amount)) / 100);
  //       washingtonRef
  //           .update({
  //               balance: increment
  //           })
  //           .then(function () {
  //               var userid = f.auth().currentUser;
  //               var userids = userid.uid;
  //               var washingtonRef2 = db.collection("users").doc(userids);
  //               // set referreduserid to "nnnnn" 
  //               washingtonRef2
  //                   .update({
  //                       referreduserid: "nnnnn"
  //                   })

  //           })
  //           .catch(function (error) {
  //               console.log("Error updating balance");

  //           });
  //   }
  // }

  return (
    <div>

      <div className="container-fluid container-application">
        {/* Sidenav */}
        <Nav />
        {/* Content */}
        <div className="main-content position-relative">
          {/* Main nav */}
          {/* Main nav */}
          <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
            <div className="container-fluid">
              {/* Brand + Toggler (for mobile devices) */}
              <div className="pl-4 d-block d-md-none">
                <a className="navbar-brand" href="/dashboard">
                  <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                </a>
              </div>
              {/* User's navbar */}
              <div className="ml-auto navbar-user d-lg-none">
                <ul className="flex-row navbar-nav align-items-center">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user-circle fa-2x" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Navbar nav */}
              <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                {/* Right menu */}
                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                  <li className="nav-item">
                    <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link nav-link-icon" href="#">
                      <i className="fas fa-user-check" />
                      <strong style={{ fontSize: '8px' }}>Verified</strong>
                    </a>
                  </li>
                  <li className="nav-item dropdown dropdown-animate">
                    <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="media media-pill align-items-center">
                        <span className="avatar rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                        <div className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                      <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                      <a href="/dashboard/account-settings" className="dropdown-item">
                        <i className="far fa-user" />
                        <span>My profile</span>
                      </a>
                      <div className="dropdown-divider" />
                      <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                        <i className="far fa-sign-out-alt" />
                        <span>Logout</span>
                      </a>
                      <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {/* Page content */}
          <div className="page-content">
            {/* Page title */}
            <div className="page-title">
              <div className="row justify-content-between align-items-center">
                <div className="mb-3 col-md-6 mb-md-0">
                  <h5 className="mb-0 text-white h3 font-weight-400">Get started with your investment.</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="mt-4 row">
                        <div className="col-md-8">
                          <div className="card">
                            <div>
                            </div>                    <div>
                            </div>                    <div className="card-body">
                              {/* Remove 'active' class, this is just to show in Codepen thumbnail */}
                              <div className="mt-5">
                                <div className>
                                  <p> Enter Your Amount</p>
                                  <div>
                                    <input type="number" required name id className="form-control d-block w-100" onChange={(e) => { setamount(e.target.value) }} placeholder={1000} min={8000} max={20000} />
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5">
                                <div className>
                                  <p>Choose Plan to Invest</p>
                                </div>
                                <div className="flex-wrap mb-1 d-flex justify-content-start align-items-center">
                                  <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("1") }}>BASIC BUNDLE<br /> $20 - $2,999 </button>
                                  <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("2") }}>BUSINESS BUNDLE <br /> $3,000 - $9,999</button>
                                  <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("3") }}>PREMIUM BUNDLE <br /> $10,000 - $10,000,000 </button>
                                  {/* <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("4") }}>MINING </button>
                                  <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("5") }}>OIL AND GAS CONTRACT </button>
                                  <button className="mb-2 border-black rounded-none btn btn-light" onClick={() => { planactivation("6") }}>REAL ESTATE CONTRACT</button> */}
                                </div>
                              </div>



                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="card">
                            <div className="card-body">
                              <p>Your Investment Details</p>
                              <div className="row">
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Name of plan</p>
                                  <span className="text-primary small">Platinum</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Plan Price</p>
                                  <span className="text-primary small">$20000</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Duration</p>
                                  <span className="text-primary small">1 Days</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Profit</p>
                                  <span className="text-primary small">
                                    1%
                                    Monthly
                                  </span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Minimum Deposit</p>
                                  <span className="text-primary small">$8000</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Maximum Deposit</p>
                                  <span className="text-primary small">$20000</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Minimum Return</p>
                                  <span className="text-primary small">1%</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Maximum Return</p>
                                  <span className="text-primary small">1.01%</span>
                                </div>
                                <div className="mb-3 col-md-6">
                                  <p className="mb-0 small">Bonus</p>
                                  <span className="text-primary small">$0</span>
                                </div>
                              </div>
                              <hr />
                              <div className="justify-content-between d-md-flex">
                                <span className="small d-block d-md-inline">Payment method:</span>
                                <span className="small text-primary">Account Balance</span>
                              </div>
                              <hr />
                              <div className="justify-content-between d-md-flex">
                                <span className="d-block d-md-inline font-weight-bold">Amount to Invest:</span>
                                <span className="text-primary font-weight-bold">${amount}</span>
                              </div>
                              <div className="mt-3 text-center">
                                <form action >
                                  <button className="px-3 btn btn-primary" disabled>
                                    Confirm &amp; Invest
                                  </button>
                                </form>
                                <span className="mt-2 small text-primary" >
                                </span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* Livewire Component wire-end:mxgWfrmU0MLN2fbIs2Ki */}              </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
          <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
            <div className="text-center row text-sm-left align-items-sm-center">
              <div className="col-sm-6">
                <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                  2023</p>
              </div>
              <div className="text-right col-sm-6 text-md-center">
                <div id="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Scripts */}
      {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
      {/* Bootstrap Notify */}
      {/* Page JS */}
      {/* Purpose JS */}
      {
        loading && (
          <div id="backdrop">
            <div class="text-center loading">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

        )
      }
    </div>
  )
}

export default TradingPlans