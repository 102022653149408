import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Dashboard() {


  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(true)
  const [Withdrawalarray, setWithdrawalarray] = useState([])
  const [Depositarry, setDepositarry] = useState([])
  const [Profitarray, setProfitarray] = useState([])

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0)

  const [open, setOpen] = React.useState(true);
  const [dataready, setdataready] = useState(false)
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  function cleanDate(d) {
    var date = new Date(d);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    console.log("Date: " + date.getDate() +
      "/" + (months[date.getMonth() + 1]) +
      "/" + date.getFullYear() +
      " " + date.getHours() +
      ":" + date.getMinutes() +
      ":" + date.getSeconds());
    return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (loggedin) {

      console.log(userdetails);
      console.log(userdetails.email);
      console.log(userdetails.Deposithistory)
      setOpen(!open);
      planduecheck(userdetails)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false)

        } else {
          setloggedin(false);
          setOpen(!open);
          navigate("/");
        }
      });
    }
  }, []);


  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setOpen(!open);
          planduecheck(doc.data())
          setloggedin(true)
          console.log(userdetails.withdrawalhistory)
          console.log(userdetails.email);

console.log(doc.data().Deposithistory)
console.log((doc.data().Withdrawalhistory))
          setDepositarry(doc.data().Deposithistory)
          setWithdrawalarray(doc.data().Withdrawalhistory)
          setdataready(true)

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };


  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const planduecheck = (info) => {
    const d1 = new Date();
    if (info.currentSubscription != null) {
      // && info.currentSubscription.dueDate !==null
      if (d1.getTime() >= info.currentSubscription.dueDate) {
        //plan matured send mail to admin and increment acountball with due bal
        // set info.currentSubscription to null in firebase
        const newBal =
          parseInt(info.balance) +
          parseInt(info.currentSubscription.dueAmount);

        updateUserBalanceandSub(newBal, info.currentSubscription.dueAmount);
        console.log(info.currentSubscription.dueDate);
      } else if (d1.getTime() < info.currentSubscription.dueDate) {

        var today = new Date();
        var total = info.currentSubscription.dueDate - info.currentSubscription.dateSubscribed;
        var progress = today - info.currentSubscription.dateSubscribed;

        console.log(Math.round(progress / total * 100) + "%");
        const currentprofit = ((progress / total * 100) * info.currentSubscription.dueAmount) / 100
        console.log(currentprofit)
        intrestprogress(currentprofit)
        setprofits(currentprofit)

        console.log(info.currentSubscription.dueDate - d1.getTime());
        //plan not yet matured show current progress of app
        const planprogress =
          ((info.currentSubscription.dueDate - d1.getTime()) /
            info.currentSubscription.dueDate) *
          100;
        console.log(planprogress);
        let date = new Date(info.currentSubscription.dueDate);
        console.log(date.toString());
      }
    } else {
      console.log(info.email);
      //show invest button
    }
  };


  const intrestprogress = (bal) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
    washingtonRef
      .update({
        // Totalearningsss: bal,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };

  const updateUserBalanceandSub = (bal, addedbal) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: null,
        // Totalearningsss: addedbal,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };


  const copyAlert = () => {
    navigator.clipboard.writeText(`https://capitalgoldtrades.com/Register.html?referralid=${f.auth().currentUser.uid} `);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `Refferal Link Copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  return (
    <div>


      <div>
        {/* Application container */}
        <div className="container-fluid container-application">
          {/* Sidenav */}
          <Nav />


          {/* Content */}
          <div className="main-content position-relative">
            {/* Main nav */}
            {/* Main nav */}
            <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
              <div className="container-fluid">
                {/* Brand + Toggler (for mobile devices) */}
                <div className="pl-4 d-block d-md-none">
                  <a className="navbar-brand" href="/dashboard">
                    <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                  </a>
                </div>
                {/* User's navbar */}
                <div className="ml-auto navbar-user d-lg-none">
                  <ul className="flex-row navbar-nav align-items-center">
                    <li className="nav-item">
                      <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                    </li>
                    <li className="nav-item dropdown dropdown-animate">
                      <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="avatar avatar-sm rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                        <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                        <a href="/dashboard/account-settings" className="dropdown-item">
                          <i className="far fa-user" />
                          <span>My profile</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                          <i className="far fa-sign-out-alt" />
                          <span>Logout</span>
                        </a>
                        <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                          <input type="hidden" name="_token" defaultValue="ej8Cog6hsY5gfbuOV15ye7J7DbCjCQEOJgkrticJ" />
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Navbar nav */}
                <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                  {/* Right menu */}
                  <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                    <li className="nav-item">
                      <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                    </li>
                    <li className="nav-item dropdown dropdown-animate">
                      <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="media media-pill align-items-center">
                          <span className="avatar rounded-circle">
                            <i className="fas fa-user-circle fa-2x" />
                          </span>
                          <div className="ml-2 d-none d-lg-block">
                            <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                        <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                        <a href="/dashboard/account-settings" className="dropdown-item">
                          <i className="far fa-user" />
                          <span>My profile</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                          <i className="far fa-sign-out-alt" />
                          <span>Logout</span>
                        </a>
                        <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                          <input type="hidden" name="_token" defaultValue="ej8Cog6hsY5gfbuOV15ye7J7DbCjCQEOJgkrticJ" />
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* Page content */}
            <div className="page-content">
              {/* Page title */}
              <div className="page-title">
                <div className="row justify-content-between align-items-center">
                  <div className="mb-3 col-md-6 mb-md-0">
                    <h5 className="mb-0 text-white h3 font-weight-400">Welcome, {userdetails.fullname}</h5>
                  </div>
                </div>
              </div>
              <div>
              </div>
              <div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="nk-block-head-content">
                            <h5 className="text-primary h5">Account Summary</h5>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Account balance</h6>
                                  <span className="mb-0 h5 font-weight-bold">$ {userdetails.balance}</span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-sack-dollar" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Total Profit</h6>
                                  <span className="mb-0 h5 font-weight-bold">$ {userdetails.totalearnings}</span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-coins" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Bonus</h6>
                                  <span className="mb-0 h5 font-weight-bold">$ {userdetails.amountBonus}</span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-gift" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Trading Accounts</h6>
                                  <span className="mb-0 h5 font-weight-bold">               {
                                    dataready && (
                                      <>{userdetails.TradingAccounts.length}</>)
                                  }</span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-th-list" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Referral Bonus</h6>
                                  <span className="mb-0 h5 font-weight-bold">$ 0.00</span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-gifts" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Total Deposit</h6>
                                  <span className="mb-0 h5 font-weight-bold">
                                    <span className="mb-0 h5 font-weight-bold ">$ {userdetails.Totaldeposit}
                                    </span>
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-arrow-alt-circle-down" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-stats">
                            {/* Card body */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h6 className="mb-1 text-muted">Total Withdrawal</h6>
                                  <span className="mb-0 h5 font-weight-bold">
                                    $ {userdetails.Totalwithdrawal}
                                  </span>
                                </div>
                                <div className="col-auto">
                                  <div className="text-white icon bg-gradient-primary rounded-circle icon-shape">
                                    <i className="fas fa-arrow-circle-up" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        userdetails.currentSubscription == null && (
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="mt-4 row">
                                  <div className="col-md-12">
                                    <div className="py-4 card">
                                      <div className="text-center card-body">
                                        <p>You do not have an investment plan at the moment or no value match your query.
                                        </p>
                                        <a href="/TradingPlans" className="px-3 btn btn-primary">Buy a plan</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      {
                        userdetails.currentSubscription != null && (
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <p>Your Current Investment Details</p>
                                <div className="row">
                                  <div className="mb-3 col-md-6">
                                    <p className="mb-0 small">Name of plan</p>
                                    <span className="text-primary small">{userdetails.currentSubscription.currentPlan}</span>
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <p className="mb-0 small">Current Profit</p>
                                    <span className="text-primary small">{profits} </span>
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <p className="mb-0 small">Plan Price</p>
                                    <span className="text-primary small">${userdetails.currentSubscription.amount}</span>
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <p className="mb-0 small">Expiry Date</p>
                                    <span className="text-primary small">{ } {new Date(userdetails.currentSubscription.dueDate).toDateString()}</span>
                                  </div>
                                  <div className="mb-3 col-md-6">
                                    <p className="mb-0 small">Maximum Return</p>
                                    <span className="text-primary small">${userdetails.currentSubscription.dueAmount}</span>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      <div className="mt-4 row">
                        <div className="col-12">
                          <div className="nk-block-head-content">
                            <h6 className="text-primary h5">Recent transactions <span className="text-base count">(10)</span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="mb-2 text-right">
                                <a href="/dashboard/accounthistory">
                                  <i className="fas fa-clipboard" /> View
                                  all
                                  transactions</a>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-striped">
                                  <thead>
                                    <tr className="bg-light">
                                      <th>Date</th>
                                      <th>Type</th>
                                      <th>Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                dataready && (
                                  userdetails.Withdrawalhistory.map((obj, i) => (
                                    <tr>
                                      <td>{obj.mode}</td>
                                      <td>${obj.amt}
                                      </td>
                                      <td>{obj.coin}</td>
                                      <td>{new Date(obj.date).toLocaleString()}
                                      </td>
                                    </tr>

                                  ))

                                )
                              }
                              {
                                dataready && (
                                  userdetails.Deposithistory.map((obj, i) => (
                                    <tr>
                                      <td>{obj.mode}</td>
                                      <td>${obj.amt}
                                      </td>
                                      <td>{obj.coin}</td>
                                      <td>{new Date(obj.date).toLocaleString()}
                                      </td>
                                    </tr>

                                  ))

                                )
                              }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="text-black">Refer Us &amp; Earn</h5>
                              <p>Use the below link to invite your friends.</p>
                              <div className="mb-3 input-group">
                                {
                                  loggedin && (
                                    <input type="text" className="form-control readonly" defaultValue={`https://capitalgoldfinex.com/Register.html?referralid=${f.auth().currentUser.uid} `} id="reflink" readOnly />
                                  )
                                }
                                <div className="input-group-append">
                                  <button className="btn btn-outline-primary" onClick={copyAlert} type="button">
                                    <i className="fas fa-copy" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
              <div className="text-center row text-sm-left align-items-sm-center">
                <div className="col-sm-6">
                  <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                    2023</p>
                </div>
                <div className="text-right col-sm-6 text-md-center">
                  <div id="google_translate_element">
                    <div className="skiptranslate goog-te-gadget" dir="ltr" style={{}}>
                      <div id=":0.targetLanguage"><select className="goog-te-combo" aria-label="Language Translate Widget">
                        <option value>Select Language</option>
                        <option value="af">Afrikaans</option>
                        <option value="sq">Albanian</option>
                        <option value="am">Amharic</option>
                        <option value="ar">Arabic</option>
                        <option value="hy">Armenian</option>
                        <option value="as">Assamese</option>
                        <option value="ay">Aymara</option>
                        <option value="az">Azerbaijani</option>
                        <option value="bm">Bambara</option>
                        <option value="eu">Basque</option>
                        <option value="be">Belarusian</option>
                        <option value="bn">Bengali</option>
                        <option value="bho">Bhojpuri</option>
                        <option value="bs">Bosnian</option>
                        <option value="bg">Bulgarian</option>
                        <option value="my">Burmese</option>
                        <option value="ca">Catalan</option>
                        <option value="ceb">Cebuano</option>
                        <option value="ny">Chichewa</option>
                        <option value="zh-CN">Chinese (Simplified)</option>
                        <option value="zh-TW">Chinese (Traditional)</option>
                        <option value="co">Corsican</option>
                        <option value="hr">Croatian</option>
                        <option value="cs">Czech</option>
                        <option value="da">Danish</option>
                        <option value="dv">Dhivehi</option>
                        <option value="doi">Dogri</option>
                        <option value="nl">Dutch</option>
                        <option value="eo">Esperanto</option>
                        <option value="et">Estonian</option>
                        <option value="ee">Ewe</option>
                        <option value="tl">Filipino</option>
                        <option value="fi">Finnish</option>
                        <option value="fr">French</option>
                        <option value="fy">Frisian</option>
                        <option value="gl">Galician</option>
                        <option value="ka">Georgian</option>
                        <option value="de">German</option>
                        <option value="el">Greek</option>
                        <option value="gn">Guarani</option>
                        <option value="gu">Gujarati</option>
                        <option value="ht">Haitian Creole</option>
                        <option value="ha">Hausa</option>
                        <option value="haw">Hawaiian</option>
                        <option value="iw">Hebrew</option>
                        <option value="hi">Hindi</option>
                        <option value="hmn">Hmong</option>
                        <option value="hu">Hungarian</option>
                        <option value="is">Icelandic</option>
                        <option value="ig">Igbo</option>
                        <option value="ilo">Ilocano</option>
                        <option value="id">Indonesian</option>
                        <option value="ga">Irish Gaelic</option>
                        <option value="it">Italian</option>
                        <option value="ja">Japanese</option>
                        <option value="jw">Javanese</option>
                        <option value="kn">Kannada</option>
                        <option value="kk">Kazakh</option>
                        <option value="km">Khmer</option>
                        <option value="rw">Kinyarwanda</option>
                        <option value="gom">Konkani</option>
                        <option value="ko">Korean</option>
                        <option value="kri">Krio</option>
                        <option value="ku">Kurdish (Kurmanji)</option>
                        <option value="ckb">Kurdish (Sorani)</option>
                        <option value="ky">Kyrgyz</option>
                        <option value="lo">Lao</option>
                        <option value="la">Latin</option>
                        <option value="lv">Latvian</option>
                        <option value="ln">Lingala</option>
                        <option value="lt">Lithuanian</option>
                        <option value="lg">Luganda</option>
                        <option value="lb">Luxembourgish</option>
                        <option value="mk">Macedonian</option>
                        <option value="mai">Maithili</option>
                        <option value="mg">Malagasy</option>
                        <option value="ms">Malay</option>
                        <option value="ml">Malayalam</option>
                        <option value="mt">Maltese</option>
                        <option value="mi">Maori</option>
                        <option value="mr">Marathi</option>
                        <option value="mni-Mtei">Meiteilon (Manipuri)</option>
                        <option value="lus">Mizo</option>
                        <option value="mn">Mongolian</option>
                        <option value="ne">Nepali</option>
                        <option value="no">Norwegian</option>
                        <option value="or">Odia (Oriya)</option>
                        <option value="om">Oromo</option>
                        <option value="ps">Pashto</option>
                        <option value="fa">Persian</option>
                        <option value="pl">Polish</option>
                        <option value="pt">Portuguese</option>
                        <option value="pa">Punjabi</option>
                        <option value="qu">Quechua</option>
                        <option value="ro">Romanian</option>
                        <option value="ru">Russian</option>
                        <option value="sm">Samoan</option>
                        <option value="sa">Sanskrit</option>
                        <option value="gd">Scots Gaelic</option>
                        <option value="nso">Sepedi</option>
                        <option value="sr">Serbian</option>
                        <option value="st">Sesotho</option>
                        <option value="sn">Shona</option>
                        <option value="sd">Sindhi</option>
                        <option value="si">Sinhala</option>
                        <option value="sk">Slovak</option>
                        <option value="sl">Slovenian</option>
                        <option value="so">Somali</option>
                        <option value="es">Spanish</option>
                        <option value="su">Sundanese</option>
                        <option value="sw">Swahili</option>
                        <option value="sv">Swedish</option>
                        <option value="tg">Tajik</option>
                        <option value="ta">Tamil</option>
                        <option value="tt">Tatar</option>
                        <option value="te">Telugu</option>
                        <option value="th">Thai</option>
                        <option value="ti">Tigrinya</option>
                        <option value="ts">Tsonga</option>
                        <option value="tr">Turkish</option>
                        <option value="tk">Turkmen</option>
                        <option value="ak">Twi</option>
                        <option value="uk">Ukrainian</option>
                        <option value="ur">Urdu</option>
                        <option value="ug">Uyghur</option>
                        <option value="uz">Uzbek</option>
                        <option value="vi">Vietnamese</option>
                        <option value="cy">Welsh</option>
                        <option value="xh">Xhosa</option>
                        <option value="yi">Yiddish</option>
                        <option value="yo">Yoruba</option>
                        <option value="zu">Zulu</option>
                      </select></div>Powered by <span style={{ whiteSpace: 'nowrap' }}><a className="VIpgJd-ZVi9od-l4eHX-hSRGPd" href="https://translate.google.com" target="_blank"><img src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png" width="37px" height="14px" style={{ paddingRight: '3px' }} alt="Google Translate" />Translate</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
    </div>
  );
}

export default Dashboard;



