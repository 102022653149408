import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function Withdrawal() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(1)
    const [coin, setcoin] = useState("")
    const [wallet, setwallet] = useState("")
    const [Amount, setAmount] = useState("")

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const SetcoinTouse=(no)=>{
         
        if(no==2){
            setcoin("Tether")
        }
        if(no==3){
            setcoin("BUSD")
        }
        if(no==4){
            setcoin("USDT")
        }
        if(no==5){
            setcoin("Litecoin")
        }
        if(no==6){
            setcoin("Ethereum")
        }
        if(no==7){
            setcoin("Bitcoin")
        }
        setstep(2)
    }
    const confirmWitdrawal = async () => {

        if (Amount != "") {
            console.log(Amount)
            console.log(userdetails.balance)
            if (parseFloat(userdetails.balance) < parseFloat(Amount)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "balance not enough",
                    footer: '<a href="">Why do I have this issue?</a>'
                })
            } else {
                await updatewithdrawalhistory()
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "no Amount selected",
                footer: '<a href="">Why do I have this issue?</a>'
            })
        }
    }


    const updatewithdrawalhistory = async () => {

        if (coin == "" || Amount == "" || wallet == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Select wallet, Coin , and Amount to procecced withdrawal",
                footer: '<a href="">Why do I have this issue?</a>'
            })
            return 0
        }
        // console.log(cointype)
        setloading(true)
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            Totalwithdrawal: increment,
            Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",

            }),
        });
        await updateUserBalance()
        await CreatWithdrawalObj()
    };

    const updateUserBalance = async (bal) => {
        const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = await db.collection("users").doc(userids);
        washingtonRef
            .update({
                balance: newEarings,
            })
            .then(function () {
                console.log("Document successfully up2dated!");
                // alert("withdrawal was successful we will get back to you");
                Swal.fire({
                    icon: 'success',
                    title: 'withdrawal was successful we will get back to you!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata();
    };
    const CreatWithdrawalObj = async () => {
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        let uobj = {
            fullname: userdetails.fullname,
            email: userdetails.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userid: userids,
            info: {
                amt: Amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coin,
                status: "pending",
            }
        };
        const userdetail1 = await db
            .collection("withdrawals")
            .add(uobj)
            .then(function () {
                console.log("Document successfully written!");
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };

    return (
        <div>

            <div className="container-fluid container-application">
                {/* Sidenav */}
                <Nav />
                {/* Content */}
                <div className="main-content position-relative">
                    {/* Main nav */}
                    {/* Main nav */}
                    <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
                        <div className="container-fluid">
                            {/* Brand + Toggler (for mobile devices) */}
                            <div className="pl-4 d-block d-md-none">
                                <a className="navbar-brand" href="/dashboard">
                                    <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                                </a>
                            </div>
                            {/* User's navbar */}
                            <div className="ml-auto navbar-user d-lg-none">
                                <ul className="flex-row navbar-nav align-items-center">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="avatar avatar-sm rounded-circle">
                                                <i className="fas fa-user-circle fa-2x" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                                            <a href="/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* Navbar nav */}
                            <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                                {/* Right menu */}
                                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="media media-pill align-items-center">
                                                <span className="avatar rounded-circle">
                                                    <i className="fas fa-user-circle fa-2x" />
                                                </span>
                                                <div className="ml-2 d-none d-lg-block">
                                                    <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                                            <a href="/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                    document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* Page content */}
                    <div className="page-content">
                        {/* Page title */}
                        <div className="page-title">
                            <div className="row justify-content-between align-items-center">
                                <div className="mb-3 col-md-6 mb-md-0">
                                    <h5 className="mb-0 text-white h3 font-weight-400">Place a withdrawal request</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>    <div>
                        </div>    <div className="row">



                            {/* Ist Step Component*/}
                            {
                                step == 1 && (
                                    <div className="my-5 col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="my-5 row d-flex nowrap">
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">Tether</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$1</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$2,343</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>percentage</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                                10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong />
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="Tether" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" onClick={() => { SetcoinTouse(2) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">BUSD</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$0</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$1,000</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>percentage</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                                10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong />
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="BUSD" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" onClick={() => { SetcoinTouse(3) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">USDT</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$0</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$100</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>percentage</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                                10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong>Instant Payment</strong>
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="USDT" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" type="button" onClick={() => { SetcoinTouse(4) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">Litecoin</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$100</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$100,000</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>0</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                            10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong>Instant</strong>
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="Litecoin" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" type="button" onClick={() => { SetcoinTouse(5) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">Ethereum</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$10</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$2,100</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>percentage</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                                10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong>Instant</strong>
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="Ethereum" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" type="button" onClick={() => { SetcoinTouse(6) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 col-lg-4">
                                                        <div className="card-deck">
                                                            <div className="text-center border-0 rounded-lg shadow-lg card card-pricing hover-scale-110 bg-primary popular">
                                                                <div className="py-0 border-0 card-header">
                                                                    <span className="px-4 py-1 mx-auto bg-white shadow-sm h6 d-inline-block rounded-bottom">Bitcoin</span>
                                                                    <div className="py-5">
                                                                        <img src="https://getonlinetrader.pro/otprocl/dash2/img/Wallet.svg.png" alt="withdrawal method image" srcSet className="img-fluid img-center" style={{ height: '90px' }} />
                                                                    </div>
                                                                </div>
                                                                <hr className="my-0 divider divider-fade divider-dark" />
                                                                <div className="card-body">
                                                                    <ul className="mb-4 text-white list-unstyled">
                                                                        <li>
                                                                            <small>Minimum withdrawable amount</small>
                                                                            <p className="text-white h5">$10</p>
                                                                        </li>
                                                                        <li>
                                                                            <small>Maximum withdrawable amount</small>
                                                                            <p className="text-white h5">$100,000</p>
                                                                        </li>
                                                                        <li>Charge Type: <strong>percentage</strong></li>
                                                                        <li>
                                                                            Withdrawal fee:
                                                                            <strong>
                                                                                10%
                                                                            </strong>
                                                                        </li>
                                                                        <li>
                                                                            Duration: <strong>Instant</strong>
                                                                        </li>
                                                                    </ul>
                                                                    <form >
                                                                        <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />													<div className="form-group">
                                                                            <input type="hidden" defaultValue="Bitcoin" name="method" />
                                                                            <button className="mb-3 btn btn-sm btn-neutral" type="button" onClick={() => { SetcoinTouse(7) }}><i className="fa fa-plus" /> Request withdrawal</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Withdrawal Modal */}
                                                <div id="withdrawdisabled" className="modal fade" role="dialog">
                                                    <div className="modal-dialog">
                                                        {/* Modal content*/}
                                                        <div className="modal-content">
                                                            <div className="modal-header ">
                                                                <h6 className="modal-title">Withdrawal Status</h6>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body ">
                                                                <h6 className>Withdrawal is Disabled at the moment, Please check
                                                                    back later</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /Withdrawals Modal */}
                                            </div>
                                        </div>
                                    </div>

                                )
                            }


                            {
                                step == 2 && (
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="mb-5 row">
                                                    <div className="col-lg-8 offset-md-2">
                                                        <div className="p-2 rounded p-md-4 card ">
                                                            <div className="card-body">
                                                                <div className="mb-3 alert alert-modern alert-success">
                                                                    <span className="text-center badge badge-success badge-pill">
                                                                        Your payment method
                                                                    </span>
                                                                    <span className="alert-content">{coin}</span>
                                                                </div>
                                                                <form action="/dashboard/completewithdrawal" method="post">
                                                                    <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />                                        <div className="form-group">
                                                                        <label className>Enter Amount to withdraw($)</label>
                                                                        <input className="form-control " placeholder="Enter Amount" type="number" name="amount" onChange={(e)=>{setAmount(e.target.value)}} required />
                                                                    </div>
                                                                    <input  type="hidden" name="method" />
                                                                    <div className="form-group">
                                                                        <h5 className>Enter {coin} Address </h5>
                                                                        <input className="form-control " placeholder="Enter coin Address" onChange={(e)=>{setwallet(e.target.value)}} type="text" name="details" required />
                                                                        <small className>{coin} is not a default withdrawal option in your account, please enter the correct wallet address to recieve your funds.</small>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button className="btn btn-primary" type="button" onClick={confirmWitdrawal}>Complete Request</button>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <button className="btn btn-primary" type="button" onClick={() => { setstep(1) }}>Cancle</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/* Footer */}
                    <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
                        <div className="text-center row text-sm-left align-items-sm-center">
                            <div className="col-sm-6">
                                <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                                    2023</p>
                            </div>
                            <div className="text-right col-sm-6 text-md-center">
                                <div id="google_translate_element" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Scripts */}
            {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
            {/* Bootstrap Notify */}
            {/* Page JS */}
            {/* Purpose JS */}
            {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
        </div>
    )
}

export default Withdrawal