import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Deposit() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)
  const [Coins, setCoins] = useState([]);


  useEffect(() => {
    adminfetchall();
  }, []);

  const adminfetchall = async () => {
    const data = await db
      .collection("allcoins")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let User = doc.data();
          User.id = doc.id;
          Coins.push(User);
          console.log(User)
        });
        console.log(snapshot.docs[snapshot.docs.length - 1])
        // setlastdocument(snapshot.docs[snapshot.docs.length - 1])
      });
    setloading(false)
  };

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)


        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };









  const [CoinInfo, setCoinInfo] = useState({})
  const [Amount, setAmount] = useState(0)
  const [image, setimage] = useState(null);
  const [url, seturl] = useState("");
  const [progress, setprogress] = useState(0);
  const [imagepresnt, setimagepresent] = useState(false);
  const [prevfile, setprevFile] = useState("")

  const Proceed = () => {
    if (Amount == 0 || CoinInfo == {}) {
      Swal.fire({
        icon: 'error',
        title: 'NO Amount Or Coin Selected',
        text: 'Insert A valid Amount and Select a coin',
      })
      return
    }
    setstep(2)
  }



  const SetCoinInfo = (value) => {
    if (value == 1) {
      // bitcoin
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected BUSD as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })

      let SelectedCoin = Coins.find(o => o.name === "BUSD");
      setCoinInfo(SelectedCoin)
    } else if (value == 2) {
      //ethereum
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected USDT as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })

      let SelectedCoin = Coins.find(o => o.name === "USDT");
      setCoinInfo(SelectedCoin)

    } else if (value == 3) {
      //dodge
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Litecoin as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Litecoin");
      setCoinInfo(SelectedCoin)

    } else if (value == 4) {
      //tron
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Ethereum as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Ethereum");
      setCoinInfo(SelectedCoin)

    } else if (value == 5) {
      //usdterc20
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Bitcoin as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Bitcoin");
      setCoinInfo(SelectedCoin)
    } else if (value == 6) {
      //usdterc20
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Shiba as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Shiba");
      setCoinInfo(SelectedCoin)
    } else if (value == 7) {
      //usdterc20
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Doge as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Doge");
      setCoinInfo(SelectedCoin)
    } else if (value == 8) {
      //usdterc20
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Solana as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Solana");
      setCoinInfo(SelectedCoin)
    } else if (value == 9) {
      //usdterc20
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You Selected Cardano as Payment Method',
        showConfirmButton: false,
        timer: 1500
      })
      let SelectedCoin = Coins.find(o => o.name === "Cardano");
      setCoinInfo(SelectedCoin)
    }
  }


  const copyAlert = () => {
    navigator.clipboard.writeText(CoinInfo.address);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `${CoinInfo.name} Address Was Copied Please Proceed To Payment`,
      showConfirmButton: false,
      timer: 1500
    })
  }


  const handleChange = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      setimagepresent(true);
      setprevFile(URL.createObjectURL(e.target.files[0]))
    }
  };

  const handleUpload = () => {
    if (!imagepresnt) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No Image Was Selected!',
      })
      return
    }
    //check wether amt is empty or file is not selected
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            seturl(url);
            updatehistory(url);
            setprogress(0);
            setimagepresent(false)
            // sendAdminMaiil()

            Swal.fire(
              'Succesful Transaction!',
              'Your account will be credited once the payment is recieved.',
              'success'
            )
            // alert("we will we credit your balance shortly");
            setimage(null);
            // history("/dashboard")
          });
      }
    );
  };

  // const sendAdminMaiil = async () => {
  //   var templateParams = {
  //     message: `${userdetails.email} have successfully Deposited $${Amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  check admin dashboard to verify`,
  //   };
  //   await emailjs
  //     .send(
  //       "service_oygvyyfwj",
  //       "template_c3syy21n6",
  //       templateParams,
  //       "BSID0Qyf_bTyyBAHW2E"
  //     )
  //     .then(
  //       function (response) {
  //       },
  //       function (err) {
  //         alert("FAILED...", err);
  //       }
  //     );
  // }

  const CreatUserObj = async (obj) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: obj

    };

    const userdetail1 = await db
      .collection("deposits")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  const updatehistory = async (urls) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    CreatUserObj({
      image: urls,
      date: Date.now(),
      amt: Amount,
      mode: "Deposit",
      coin: CoinInfo.name
    })
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      Totaldeposit: increment,
      Deposithistory: firebase.firestore.FieldValue.arrayUnion({
        image: urls,
        date: Date.now(),
        amt: Amount,
        mode: "Deposit",
        coin: CoinInfo.name
      }),
    });
  };



  return (
    <div>


      <div>

        <div className="container-fluid container-application">
          {/* Sidenav */}
          <Nav />
          {/* Content */}
          <div className="main-content position-relative">
            {/* Main nav */}
            {/* Main nav */}
            <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
              <div className="container-fluid">
                {/* Brand + Toggler (for mobile devices) */}
                <div className="pl-4 d-block d-md-none">
                  <a className="navbar-brand" href="/dashboard">
                    <img src="/assets/images/logo.png" className="navbar-brand-img" alt="..." />
                  </a>
                </div>
                {/* User's navbar */}
                <div className="ml-auto navbar-user d-lg-none">
                  <ul className="flex-row navbar-nav align-items-center">
                    <li className="nav-item">
                      <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                    </li>
                    <li className="nav-item dropdown dropdown-animate">
                      <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="avatar avatar-sm rounded-circle">
                          <i className="fas fa-user-circle fa-2x" />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                        <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                        <a href="/dashboard/account-settings" className="dropdown-item">
                          <i className="far fa-user" />
                          <span>My profile</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                          <i className="far fa-sign-out-alt" />
                          <span>Logout</span>
                        </a>
                        <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                          <input type="hidden" name="_token" defaultValue="ej8Cog6hsY5gfbuOV15ye7J7DbCjCQEOJgkrticJ" />
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Navbar nav */}
                <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                  {/* Right menu */}
                  <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                    <li className="nav-item">
                      <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                    </li>
                    <li className="nav-item dropdown dropdown-animate">
                      <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="media media-pill align-items-center">
                          <span className="avatar rounded-circle">
                            <i className="fas fa-user-circle fa-2x" />
                          </span>
                          <div className="ml-2 d-none d-lg-block">
                            <span className="mb-0 text-sm font-weight-bold">{userdetails.fullname}</span>
                          </div>
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                        <h6 className="px-0 dropdown-header">Hi, {userdetails.fullname}!</h6>
                        <a href="/dashboard/account-settings" className="dropdown-item">
                          <i className="far fa-user" />
                          <span>My profile</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                          <i className="far fa-sign-out-alt" />
                          <span>Logout</span>
                        </a>
                        <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                          <input type="hidden" name="_token" defaultValue="ej8Cog6hsY5gfbuOV15ye7J7DbCjCQEOJgkrticJ" />
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* Page content */}
            <div className="page-content">
              {/* Page title */}
              <div className="page-title">
                <div className="row justify-content-between align-items-center">
                  <div className="mb-3 col-md-6 mb-md-0">
                    <h5 className="mb-0 text-white h3 font-weight-400">Fund your account balance</h5>
                  </div>
                </div>
              </div>
              <div>
              </div>    <div>
              </div>    <div className="row">




                {/* Ist Step Components */}

                {
                  step == 1 && (
                    <div className="col-md-12">
                      <div className="card ">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8">
                              <form action="javascript:;" method="post" id="submitpaymentform">
                                <input type="hidden" name="_token" defaultValue="ej8Cog6hsY5gfbuOV15ye7J7DbCjCQEOJgkrticJ" />                                <div className="row">
                                  <div className="mb-4 col-md-12">
                                    <h5 className="card-title ">Enter Amount</h5>
                                    <input className="form-control " placeholder="Enter Amount" min={5} type="number" name="amount" required onChange={(e) => setAmount(e.target.value)} />
                                  </div>
                                  <div className="mb-4 col-md-12">
                                    <input type="hidden" name="payment_method" id="paymethod" />
                                  </div>
                                  <div className="mt-2 mb-1 col-md-12">
                                    <h5 className="card-title ">Choose Payment Method from the list below</h5>
                                  </div>

                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="BUSD" id={23} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/external-black-fill-lafs/256/external-Binance-USD-cryptocurrency-black-fill-lafs.png" alt="" className style={{ width: '25px' }} />
                                            BUSD
                                          </span>
                                          <span>
                                            <input type="radio" id="23customCheck1" readOnly onClick={() => { SetCoinInfo(1) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="USDT" id={21} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=023" alt="" className style={{ width: '25px' }} />
                                            USDT
                                          </span>
                                          <span>
                                            <input type="radio" id="21customCheck1" readOnly onClick={() => { SetCoinInfo(2) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  {/* <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bank Transfer" id={12} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/pastel-glyph/256/bank-building.png" alt="" className style={{ width: '25px' }} />
                                            Bank Transfer
                                          </span>
                                          <span>
                                            <input type="radio" id="12customCheck1" readOnly />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div> */}
                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Litecoin" id={3} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/cotton/256/litecoin.png" alt="" className style={{ width: '25px' }} />
                                            Litecoin
                                          </span>
                                          <span>
                                            <input type="radio" id="3customCheck1" readOnly onClick={() => { SetCoinInfo(3) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Ethereum" id={2} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/color/256/ethereum.png" alt="" className style={{ width: '25px' }} />
                                            Ethereum
                                          </span>
                                          <span>
                                            <input type="radio" id="2customCheck1" readOnly onClick={() => { SetCoinInfo(4) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bitcoin" id={1} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/bubbles/256/bitcoin-sccepted.png" alt="" className style={{ width: '25px' }} />
                                            Bitcoin
                                          </span>
                                          <span>
                                            <input type="radio" id="1customCheck1" readOnly onClick={() => { SetCoinInfo(5) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>




                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bitcoin" id={1} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/external-konkapp-flat-konkapp/256/external-shiba-japan-konkapp-flat-konkapp.png" alt="" className style={{ width: '25px' }} />
                                            SHIBA
                                          </span>
                                          <span>
                                            <input type="radio" id="1customCheck1" readOnly onClick={() => { SetCoinInfo(6) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>


                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bitcoin" id={1} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/ios-filled/256/dogecoin.png" alt="" className style={{ width: '25px' }} />
                                            Doge
                                          </span>
                                          <span>
                                            <input type="radio" id="1customCheck1" readOnly onClick={() => { SetCoinInfo(7) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>


                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bitcoin" id={1} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/nolan/256/solana.png" alt="" className style={{ width: '25px' }} />
                                          Solana
                                          </span>
                                          <span>
                                            <input type="radio" id="1customCheck1" readOnly onClick={() => { SetCoinInfo(8) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>

                                  <div className="mb-2 col-md-6">
                                    <a style={{ cursor: 'pointer' }} data-method="Bitcoin" id={1} className="text-decoration-none" onclick="checkpamethd(this.id)">
                                      <div className="rounded border">
                                        <div className="card-body d-flex justify-content-between align-items-center">
                                          <span className>
                                            <img src="https://img.icons8.com/fluency/256/cardano.png" alt="" className style={{ width: '25px' }} />
                                            Cardano
                                          </span>
                                          <span>
                                            <input type="radio" id="1customCheck1" readOnly onClick={() => { SetCoinInfo(9) }} />
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>






                                  <div className="mt-2 mb-1 col-md-12">
                                    <input type="button" className="px-5 btn btn-primary btn-lg" defaultValue="Procced to Payment" onClick={Proceed} />
                                  </div>
                                  <input type="hidden" id="lastchosen" defaultValue={0} />
                                </div>
                              </form>
                            </div>
                            <div className="mt-4 col-md-4">
                              {/* Seller */}
                              <div className="card">
                                <div className="card-body">
                                  <div className="pb-4">
                                    <div className="row align-items-center">
                                      <div className="col-6">
                                        <h6 className="mb-0">Total Deposit</h6>
                                        <span className="text-sm text-muted">-</span>
                                      </div>
                                      <div className="col-6">
                                        <h6 className="mb-1">
                                          <b>${Amount}
                                          </b>
                                        </h6>
                                        <span className="text-sm text-muted">Amount</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer">
                                  <div className="actions d-flex justify-content-between">
                                    <a href="/dashboard/accounthistory" className="action-item">
                                      <span className="btn-inner--icon">View deposit history</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }



                {
                  step == 2 && (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-8 offset-md-2">
                              <div className="p-2 shadow-lg card p-md-4">
                                <div className="alert alert-modern alert-warning">
                                  <span className="badge badge-warning badge-pill">
                                    Your payment method
                                  </span>
                                  <span className="alert-content">{CoinInfo.name}</span>
                                </div>
                                <div className="card-body">
                                  <div>
                                    <h6 className>You are to make payment of
                                      <strong>${Amount}</strong>
                                      using
                                      your selected payment method.
                                    </h6>
                                    <h4>
                                    </h4>
                                  </div>
                                  <div className="mt-5">
                                    <h6 className="mt-4">
                                      <strong>{CoinInfo.name} Address:</strong>
                                    </h6>
                                    <div className="mb-3 form-group">
                                      <div className="input-group">
                                        <input type="text" className="form-control readonly" defaultValue={CoinInfo.address} id="reflink" readOnly />
                                        <div className="input-group-append">
                                          <button className="btn btn-outline-secondary" onClick={copyAlert} type="button" id="button-addon2"><i className="fas fa-copy" /></button>
                                        </div>
                                      </div>
                                      <small className><strong>Network Type:</strong>
                                        {CoinInfo.name}
                                      </small>
                                    </div>
                                  </div>
                                  <div>
                                    <form encType="multipart/form-data">
                                      <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />                                                <div className="form-group">
                                        <p className>Upload Payment proof after payment.</p>
                                        <input type="file" name="proof" className="form-control col-lg-8 " onChange={handleChange} required />
                                      </div>
                                      <input type="hidden" name="amount" defaultValue={300} />
                                      <input type="hidden" name="paymethd_method" defaultValue="BUSD" />


                                      {
                                        imagepresnt && (
                                          <>
                                            <progress id="file" value={progress} max="100"> {progress} </progress>
                                            <div className="form-group">
                                              <input type="button" className="btn btn-primary" defaultValue="Submit Payment" onClick={handleUpload} />
                                            </div>
                                          </>

                                        )
                                      }
                                      <div className="form-group">
                                        <input type="button" className="btn btn-primary" onClick={() => { setstep(1) }} defaultValue="Cancel" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }





              </div>
            </div>
            {/* Footer */}
            <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
              <div className="text-center row text-sm-left align-items-sm-center">
                <div className="col-sm-6">
                  <p className="mb-0 text-sm">All Rights Reserved © Capital Gold Finex
                    2023</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* Scripts */}
        {/* Core JS - includes jquery, bootstrap, popper, in-view and sticky-kit */}
        {/* Bootstrap Notify */}
        {/* Page JS */}
        {/* Purpose JS */}
   {
                loading && (
                  <div id="backdrop">
                  <div class="text-center loading">
                      <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
                </div>
                    
                )
            }
      </div>
    </div>
  );
}

export default Deposit;



