import React from 'react'
import Nav from '../Components/Nav'

function DepositPayment() {
    return (
        <div>

            <div className="container-fluid container-application">
                {/* Sidenav */}
                <Nav />
                {/* Content */}
                <div className="main-content position-relative">
                    {/* Main nav */}
                    {/* Main nav */}
                    <nav className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border" id="navbar-main">
                        <div className="container-fluid">
                            {/* Brand + Toggler (for mobile devices) */}
                            <div className="pl-4 d-block d-md-none">
                                <a className="navbar-brand" href="https://getonlinetrader.pro/otprocl/dashboard">
                                    <img src="https://getonlinetrader.pro/otprocl/storage/app/public/photos/Jo4SZ9GGvtk7CDprfhKVphNeTxsA5G7J0hx5mRFZ.png" className="navbar-brand-img" alt="..." />
                                </a>
                            </div>
                            {/* User's navbar */}
                            <div className="ml-auto navbar-user d-lg-none">
                                <ul className="flex-row navbar-nav align-items-center">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="avatar avatar-sm rounded-circle">
                                                <i className="fas fa-user-circle fa-2x" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, Big Jwett!</h6>
                                            <a href="https://getonlinetrader.pro/otprocl/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* Navbar nav */}
                            <div className="collapse navbar-collapse navbar-collapse-fade" id="navbar-main-collapse">
                                {/* Right menu */}
                                <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link nav-link-icon sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main"><i className="far fa-bars" /></a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link nav-link-icon" href="#">
                                            <i className="fas fa-user-check" />
                                            <strong style={{ fontSize: '8px' }}>Verified</strong>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown dropdown-animate">
                                        <a className="nav-link pr-lg-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="media media-pill align-items-center">
                                                <span className="avatar rounded-circle">
                                                    <i className="fas fa-user-circle fa-2x" />
                                                </span>
                                                <div className="ml-2 d-none d-lg-block">
                                                    <span className="mb-0 text-sm font-weight-bold">Big Jwett</span>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                                            <h6 className="px-0 dropdown-header">Hi, Big Jwett!</h6>
                                            <a href="https://getonlinetrader.pro/otprocl/dashboard/account-settings" className="dropdown-item">
                                                <i className="far fa-user" />
                                                <span>My profile</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a className="dropdown-item text-danger" href="https://getonlinetrader.pro/otprocl/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                                                <i className="far fa-sign-out-alt" />
                                                <span>Logout</span>
                                            </a>
                                            <form id="logout-form" action="https://getonlinetrader.pro/otprocl/logout" method="POST" style={{ display: 'none' }}>
                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* Page content */}
                    <div className="page-content">
                        {/* Page title */}
                        <div className="page-title">
                            <div className="row justify-content-between align-items-center">
                                <div className="mb-3 col-md-6 mb-md-0">
                                    <h5 className="mb-0 text-white h3 font-weight-400">Make Payment</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div><div>
                        </div><div className="row">




                            {/* Next Step Components */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-8 offset-md-2">
                                                <div className="p-2 shadow-lg card p-md-4">
                                                    <div className="alert alert-modern alert-warning">
                                                        <span className="badge badge-warning badge-pill">
                                                            Your payment method
                                                        </span>
                                                        <span className="alert-content">BUSD</span>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <h6 className>You are to make payment of
                                                                <strong>$300</strong>
                                                                using
                                                                your selected payment method.
                                                            </h6>
                                                            <h4>
                                                            </h4>
                                                        </div>
                                                        <div className="mt-5">
                                                            <h6 className="mt-4">
                                                                <strong>BUSD Address:</strong>
                                                            </h6>
                                                            <div className="mb-3 form-group">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control readonly" defaultValue="Enter your correct wallet address here" id="reflink" readOnly />
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-outline-secondary" onclick="myFunction()" type="button" id="button-addon2"><i className="fas fa-copy" /></button>
                                                                    </div>
                                                                </div>
                                                                <small className><strong>Network Type:</strong>
                                                                    ERC20
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <form method="post" action="https://getonlinetrader.pro/otprocl/dashboard/savedeposit" encType="multipart/form-data">
                                                                <input type="hidden" name="_token" defaultValue="ew88YpY8hHjBCf5iJbACAGwVPxA7Z5uFlrsNiEu8" />                                                <div className="form-group">
                                                                    <p className>Upload Payment proof after payment.</p>
                                                                    <input type="file" name="proof" className="form-control col-lg-8 " required />
                                                                </div>
                                                                <input type="hidden" name="amount" defaultValue={300} />
                                                                <input type="hidden" name="paymethd_method" defaultValue="BUSD" />
                                                                <div className="form-group">
                                                                    <input type="submit" className="btn btn-primary" defaultValue="Submit Payment" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                    {/* Footer */}
                    <div className="pt-5 pb-4 footer footer-light sticky-bottom" id="footer-main">
                        <div className="text-center row text-sm-left align-items-sm-center">
                            <div className="col-sm-6">
                                <p className="mb-0 text-sm">All Rights Reserved © OnlineTraderpro
                                    2023</p>
                            </div>
                            <div className="text-right col-sm-6 text-md-center">
                                <div id="google_translate_element" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepositPayment